import React from 'react'
import top1 from '../../images/top1.svg'
import top2 from '../../images/top2.svg'
import top3 from '../../images/top3.svg'
import Fade from 'react-reveal/Fade'

const Benefits = () => {
    return (
        <section className="benefit">
            <div className="text-xl sm:text-3xl text-white bg-mainColor p-1 text-center">Top 3 benefits for You! </div>
            <div className="cont">
                <div className="rictos">
                    <Fade bottom>
                        <div className="benefits mb-3 ">
                            <div className="p-2 sm:h-3/5">
                                <img src={top1} className="m-auto"  />
                            </div>
                            <h4>Increase Productivity</h4>
                            <p>Increase productivity by superior browsing session organization into Workspaces and Screen visualization customization.</p>
                        </div>
                    </Fade>
                    <Fade top>
                        <div className="benefits mb-3">
                            <div className=" p-2 sm:h-3/5">
                                <img src={top2} className="m-auto"/>
                            </div>
                            <h4>Easy Identities Management</h4>
                            <p>Simplify your work by parallel multiple social media profile logins and manage your notifications so you don’t miss a thing. </p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className="benefits mb-3">
                            <div className="sm:h-3/5 h">
                                <img src={top3} className="m-auto"/>
                            </div>
                            <h4>Smart Proxies</h4>
                            <p>Maintain anonymity and get access to online content from  various countries through intelligent proxy setups. </p>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}
export default Benefits